import { Row, Col } from 'react-bootstrap';
// import { BsInfoCircle, BsQuestionCircle } from 'react-icons/bs';


// component representing the footer
const Footer = (props) => {

    return (
        <>
            <Row className="footer">
                <Col>
                    { props.name } v{ props.version }{ props.build ? '+' + props.build : '' }
                </Col>
            </Row>
        </>
    );
    
}

export default Footer;