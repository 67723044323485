import { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import Store from '@model/Store';
import { exportPDF, downloadPDF } from '@model/ExportBrowserImpl';


// export
const Export = (props) => {	

    // eslint-disable-next-line
    const store_state = Store(state => state);
    const [comment, setComment] = useState(Store.getState().comment);

    const onChangeComment = (e) => {
        setComment(e.target.value);
        Store.getState().setComment(e.target.value);
    };
    
    // eslint-disable-next-line
    let log_state = () => {
        console.log(store_state);
    };

    const savePdf = ()=> {
        const pdf_doc = exportPDF(Store, props.application_program);
        
        const pdf_filename = `TUHH_${props.application_program.id.toUpperCase()}_Application_${Store.getState().id}.pdf`;
        downloadPDF(pdf_doc, pdf_filename);
    };

    return (
        <>

            <Form>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label>Your comment for us</Form.Label>
                            <Form.Control as="textarea" rows={3} className="export-comment" value={comment} onChange={onChangeComment} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label>
                                On this page you can export and download your entered information in the form of a PDF file. Before doing so, please check that all your information is correct and complete. You may also enter an optional comment for us, if you like. Click the button below once you are finished.
                            </Form.Label>
                            <Button variant="primary" className="export-button" onClick={ () => { savePdf(); } }>Export as personal PDF</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            {/* <Button variant="outline-primary"
                onClick={() => { log_state() }}>
                Log Store State
            </Button> */}

        </>

    );
    
}

export default Export;