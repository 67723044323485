import { ApplicationProgram } from '@model/Types';

import Mechatronics from '@application_programs/Mechatronics';
import MechanicalEngineeringAndManagement from '@application_programs/MechanicalEngineeringAndManagement';


export class ApplicationPrograms {

    static MEC = Object.assign(new ApplicationProgram(), Mechatronics);
    static MEM = Object.assign(new ApplicationProgram(), MechanicalEngineeringAndManagement);

    static list = [
        this.MEC,
        this.MEM
    ];

    static standard = () => { return this.MEC; }

    // checks if the given programId is valid
    static isValidId = (programId) => {
        return this.list.find((ele) => ele.id === programId) !== undefined;
    }

    // returns the program object with the given programId
    static getProgramById = (programId) => {
        return this.list.find((ele) => ele.id === programId);
    }

    // returns the programId if valid, otherwise the standard programId
    static getValidProgramId = (programId) => {
        return this.isValidId(programId) ? programId : this.standard().id;
    }

    // returns the program object with the given programId if valid, otherwise the standard program object
    static getValidProgramById = (programId) => {
        return this.getProgramById(this.getValidProgramId(programId));
    }

}