import { v4 as uuidv4 } from 'uuid';

// Type definitions as JS classes:
//  - use as empty templates
//  - use as (FP like) sugar for instantiation
//  - prepares future TS migration

let api_version = '0.1.1';
export const version = api_version;


// Application Program
export class ApplicationProgram {

    name;

    id;

    fields;

    constructor(
        name = "",
        id = "",
        fields = []
    ) {
        this.name = name;
        this.id = id;
        this.fields = fields;
    }

}

// Application Program Field
export class ApplicationProgramField {

    id;

    name;

    description;

    courses;

    constructor(
        name = "",
        description = "",
        courses = []
    ) {
        this.name = name;
        this.description = description;
        this.courses = courses;
    }

}

// Application Program Course
export class ApplicationProgramCourse {

    id;
    subject;
    description;
    required_cp;

    constructor(
        id = "",
        subject = "",
        description = "",
        required_cp = 0,
    ) {
        this.id = id;
        this.subject = subject;
        this.description = description;
        this.required_cp = parseFloat(required_cp);
    }

}

// Personal Information
export class PersonalInformation {

    // global applicant identifier [Bewerbernummer]
    id;

    surname;

    name;

    application_number;

    constructor(
        surname = "",
        name = "",
        application_number = "",
        id = uuidv4(),
    ) {
        this.id = id;
        this.surname = surname;
        this.name = name;
        this.application_number = application_number;
    }
}

export const CEFR_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

// Language Qualification
export class LanguageQualification {

    language;

    // certification for this level exists?
    cefr_certificate;

    // proficiency level: select from CEFR_LEVELS
    cefr_level;

    constructor(
        language = "",
        cefr_certificate = false,
        cefr_level = ""
    ) {
        this.language = language;
        this.cefr_certificate = cefr_certificate;
        this.cefr_level = cefr_level;
    }
}

// Employment
export class Employment {

    // industry sector
    industry;

    // company / organization
    employer;

    // job title
    role;

    description;

    constructor(
        industry = "",
        employer = "",
        role = "",
        description = ""
    ) {
        this.industry = industry;
        this.employer = employer;
        this.role = role;
        this.description = description;
    }
}

// Degree Granting Institution
export class DegreeGrantingInstitution {

    country;

    // insitution/university name
    name;

    // college
    college;

    constructor(
        country = "",
        name = "",
        college = ""
    ) {
        this.country = country;
        this.name = name;
        this.college = college;
    }
}

// Degree Program
export class DegreeProgram {

    // B.Sc, M.Sc, ...
    degree;

    // e.g. Mechatronics
    subject;

    // e.g. Robotics
    specialization;

    // in years
    duration_nominal;

    // necessary credits to pass degree (using granting institutions credit system)
    credits_nominal;

    // select or name the credit system: ECTS, ...
    credit_system;

    constructor(
        degree = "",
        subject = "",
        specialization = "",
        duration_nominal = 0,
        credits_nominal = 0,
        credit_system = ""
    ) {
        this.degree = degree;
        this.subject = subject;
        this.specialization = specialization;
        this.duration_nominal = parseFloat(duration_nominal);
        this.credits_nominal = parseFloat(credits_nominal);
        this.credit_system = credit_system;
    }
}

// Object to hold month and year combination
export class MonthYear {

    month;

    year;

    constructor(month = 0, year = 0) {
        this.month = month;
        this.year = year;
    }
}

// Degree Final Report
export class DegreeFinalReport {

    // start date; js Date Object
    date_start;

    // (projected) end date; js Date Object
    date_end;

    // completed by now
    // TODO: implicitly set from date_end?
    completed;

    // actual credits; using granting institutions credit system
    credits;

    constructor(
        date_start = new MonthYear(0, 2018),
        date_end = new MonthYear(11, 2023),
        completed = false,
        credits = 0
    ) {
        this.date_start = date_start;
        this.date_end = date_end;
        this.completed = completed;
        this.credits = parseFloat(credits);
    }
}

// Course
export class Course {

    // internal identifier
    id;

    // name
    subject;

    // degree
    degree;

    // code of the course (optional)
    course_code;

    // granted credits
    credits;

    // rationale: explanation for mapping credits, if necessary
    comment;

    // compulsory or not
    compulsory;

    constructor(
        degree = "",
        subject = "",
        course_code = "",
        credits = 0,
        comment = "",
        compulsory = true,
        id = uuidv4(),
    ) {
        this.id = id;
        this.degree = degree;
        this.subject = subject;
        this.course_code = course_code;
        this.credits = parseFloat(credits);
        this.comment = comment;
        this.compulsory = compulsory;
    }
}

export class Degree {

    id;

    granting_institution;

    program;

    report;

    constructor(
        granting_institution = new DegreeGrantingInstitution(),
        program = new DegreeProgram(),
        report = new DegreeFinalReport(),
        id = uuidv4(),
    ) {
        this.id = id;
        this.granting_institution = granting_institution;
        this.program = program;
        this.report = report;
    }
}

// Qualification Allocator
export class QualificationAllocator {

    id;

    // application program id
    application_program;

    // degree, by id from above
    degree;

    // course from degree, by id from above
    course;

    // subject to allocate credits to, from target program, by id
    target;

    // credits in source credit system (possibly NON ECTS)
    credits;

    constructor(
        application_program,
        degree,
        course,
        target,
        credits = 0,
        id = uuidv4()
    ) {
        this.application_program = application_program;
        this.id = id;
        this.degree = degree;
        this.course = course;
        this.target = target;
        this.credits = parseFloat(credits);
    }
}

// Student Application
export class StudentApplication {

    // random generated id for application
    id;

    // date of application
    date;

    // applicant personal information
    personal_information;

    // language qualification; unified representation
    language_qualification;

    // employments
    employment;

    // formal educations/qualifications
    degrees;

    // list of all courses from all degrees
    courses;

    // matching of granted credits from all degrees to necessary credits for target program
    // references:
    //  - courses from degrees
    //  - subjects from target_program
    program_qualifications;

    // last comment in summary tab
    comment;

    constructor(
        personal_information = new PersonalInformation(),
        language_qualification = {},
        employment = [],
        degrees = [],
        courses = [],
        program_qualifications = [],
        comment = '',
        id = uuidv4(),
        date = Date.now(),
    ) {
        this.id = id;
        this.date = date;
        this.personal_information = personal_information;
        this.language_qualification = language_qualification;
        this.employment = employment;
        this.degrees = degrees;
        this.courses = courses;
        this.program_qualifications = program_qualifications;
        this.comment = comment;
    }
}

// Application
export class Application {

    // identify version format
    version;

    // student provided application data
    application;

    // // reviews
    // reviews = [];

    // constructor(application, reviews, version = version) {
    //     this.version = version;
    //     this.application = application;
    //     this.reviews = reviews;
    // };

    constructor(
        application = {},
        version = api_version
    ) {
        this.version = version;
        this.application = application;
    }
}
