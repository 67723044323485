import { Accordion, Row, Col, Image } from 'react-bootstrap';
import { BsInfoCircle, BsPersonCircle, BsQuestionCircle } from 'react-icons/bs';


// component representing the header
const Header = (props) => {

    return (
        <>
            <Row className="logo-header">
                <Col sm="auto">
                    <Image src="logo-new.svg" alt="TUHH" width="172" />
                </Col>
                <Col>
                    <h1 className="top-header">Subject Specific Requirements Form</h1>
                </Col>
            </Row>
            <Row>
                <h2>Match your Credits with our Prerequisites &mdash; the Subject Specific Requirements for TUHH's International Master's Program in "{props.application_program_name}"</h2>
            </Row>
            <Row className="main-text-body">
                Welcome! Please enter your personal details as well as your program-related modules/lectures in order to match credits from your previous studies.
            </Row>
            <Row className="main-text-body proceeding">
                This helps us match the academic knowledge and skills you have achieved or will achieve prior to your potential studies at TUHH. Please fill the form to the best of your knowledge. Keep in mind that we need proof for all academic knowledge and skills (e.g. Transcript of Records).
            </Row>
            <Row className="main-text-body proceeding">
                In the end of this process, you will be asked to download your provided information in the form of a generated PDF file. You will have to upload this PDF file during the online application in the section “Upload of ECTS-Sheet”. Your PDF file may be edited later again using this tool and has to be given in along with your official application eventually.
            </Row>
            <Accordion className="additional-details" alwaysOpen>
                <Accordion.Item eventKey="howto-0">
                    <Accordion.Header>
                        <Col className="col" xs="auto">
                            <BsInfoCircle />
                        </Col>
                        <Col className="howto-details-title">
                            How To and More Details
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            At first, please choose from one of our study programs you desire to apply for.
                        </Row>
                        <Row>
                            Next, please fill in your personal details in the corresponding section.
                        </Row>
                        <Row>
                            Afterwards, pay attention to the actual requirements themselves: You will notice that each subject area under the 'Our Prerequisites' view contains specific topics you need to fulfill by assigning your priorly achieved credits thereunder. To do this, you will initially prepare your associated modules/lectures under the 'Your Modules/Lectures' view. Please note that our requirements in the specific areas are mandatory, meaning that if you already notice a lack of knowledge in one or more areas, you might not have a high chance for admission in the long run. Still, you do not need to get to 100% to gain admission.
                        </Row>
                        <Row>
                            After having gained an overview, please start by adding a degree in the 'Your Modules/Lectures' view (you can add more degrees later if you have more then one).
                        </Row>
                        <Row>
                            Then, and still in the same view, add all of your modules/lectures that are relevant to our requirements and start to assign them in the 'Our Prerequisites' view. You can switch between these two views at any time to add more modules/lectures or to redistribute them. Please note, that you can also assign partial credits of a modules/lectures to different specific topics. Use the 'Our Prerequisites' view to get an overview.
                        </Row>
                        <Row>
                            Please note: The credit point system used at TUHH corresponds to ECTS &mdash; but we do the unit conversion for you.
                        </Row>
                        <Row>
                            Finally, and if everything is to your content, you can go to the 'Export' view and generate the PDF. Feel free to leave a comment for us that will be included when you generate your PDF. You will have to upload this document in the Application Request in the section “Upload of ECTS-Sheet”.
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="howto-1">
                    <Accordion.Header>
                        <Col className="col" xs="auto">
                            <BsQuestionCircle />
                        </Col>
                        <Col className="howto-details-title">
                            FAQ &mdash; ECTS
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            What does ECTS mean? ECTS (European Credit Transfer System) credits are based on the workload students need in order to achieve expected learning outcomes. Workload indicates the time students typically spend to complete all learning activities (such as lectures, seminars, projects, practical work, self-study and examinations). 60 ECTS credits are attached to the workload of a full-time year of formal learning (academic year) and the associated learning outcomes. 1 ECTS credit corresponds to 30 hours of work load.
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="howto-2">
                    <Accordion.Header>
                        <Col className="col" xs="auto">
                            <BsPersonCircle />
                        </Col>
                        <Col className="howto-details-title">
                            How to Reach Us
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            In case you require assistance, please contact us here:&nbsp;<a className="contact-email" href="mailto:ssrf@tuhh.de">ssrf@tuhh.de</a>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    
}

export default Header;