
export const externalToInternalCredits = (totalExternalCredits, totalExternalDuration, externalCredits, totalInternalDuration = 1.0, totalInternalCredits = 30.0) => {
    totalExternalCredits = parseFloat(totalExternalCredits);
    totalExternalDuration = parseFloat(totalExternalDuration);
    externalCredits = parseFloat(externalCredits);
    totalInternalDuration = parseFloat(totalInternalDuration);
    totalInternalCredits = parseFloat(totalInternalCredits);

    let oneExternalCredit = (totalInternalCredits / totalInternalDuration) / (totalExternalCredits / totalExternalDuration);
    
    return externalCredits * oneExternalCredit;
}