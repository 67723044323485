import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist, createJSONStorage } from 'zustand/middleware'


import * as Model from '@model/Types';

const MAX_STORE_AGE = 1000 * 60 * 60 * 24 * 300; // 300 days

const Store = create(
	persist(immer((set, get) =>

		Object.assign({}, new Model.StudentApplication(), {

			// date: Date.now(),

			//////////////////////////////////////////////////////////////
			// Getters and setters
			
			setComment: (comment) => {
				set((state) => {
					return {
						...state,
						comment: comment
					};
				});
			},

			// set personal_information
			setPersonalInformationProperty: (property, value) => {
				set((state) => {
					return {
						...state,
						personal_information: {
							...state.personal_information,
							[property]: value
						}
					};
				});
			},

			// add a degree; create if none is given
			updateDegree: (degree = new Model.Degree()) => {
				let retval = degree.id;

				set((state) => {
					let idx = state.degrees.findIndex(
						(d) => d.id === degree.id
					);
					if (idx === -1) {
						state.degrees.push(degree);
					} else {
						state.degrees[idx] = degree;
					}

					return state;
				});

				return retval;
			},

			// remove a degree based on its uuid
			removeDegree: (ref) => {
				let degreeId = ref;
				// let degreeId = ref instanceof Model.Degree ? ref.id : ref;
				let retval = degreeId;

				set((state) => {
					retval = degreeId;
					return {
						...state,
						courses: state.courses.filter((c) => c.degree !== degreeId),
						degrees: state.degrees.filter((d) => d.id !== degreeId),
						program_qualifications: state.program_qualifications.filter((q) => { return q.degree !== degreeId; })
					};
				});

				// will always return id, if present in store before deletion or not
				return retval;
			},

			getDegreeById: (degreeId) => {
				return get().degrees.find((d) => d.id === degreeId);
			},

			// add a course; create if none is given
			updateCourse: (course = new Model.Course()) => {
				let retval = course.id;

				set((state) => {
					let idx = state.courses.findIndex(
						(c) => c.id === course.id
					);
					if (idx === -1) {
						state.courses.push(course);
					} else {
						state.courses[idx] = course;
					}

					return state;
				});

				return retval;
			},

			getCourseById: (courseId) => {
				return get().courses.find((c) => c.id === courseId);
			},

			// remove a course based on its uuid
			// will also remove the course from the degree: degrees[degreeId].courses
			removeCourse: (ref) => {
				let courseId = ref;
				// let courseId = ref instanceof Model.Course ? ref.id : ref;
				let retval = courseId;

				set((state) => {
					return {
						...state,
						courses: state.courses.filter((c) => c.id !== courseId),
						program_qualifications: state.program_qualifications.filter((q) => q.course !== courseId)
					};
				});

				// will always return id, if present in store before deletion or not
				return retval;

			},

			// add a new matching between a course and a prerequisite
			// degreeId: id of degree to which the course belongs
			// courseId: id of course to match
			// targetId: id of target to match
			addMatching: (
				applicationProgramId,
				courseId,
				targetId,
				credits = 0
			) => {
				let retval = null;

				set((state) => {
					let degreeId = state.courses.find((c) => c.id === courseId).degree;

					// handle double matches
					if (state.program_qualifications.findIndex(
						(q) => 	q.course === courseId &&
								q.target === targetId && 
								q.application_program === applicationProgramId
					) >= 0) {
						return state;
					}

					// add new match
					const match = new Model.QualificationAllocator(
						applicationProgramId,
						degreeId,
						courseId,
						targetId,
						credits
					);

					retval = match.id;

					return {
						...state,
						program_qualifications: [
							...state.program_qualifications,
							match
						]
					};
				});

				return retval;
			},

			// remove a matching based on its uuid (dictionary key) in programQualifications[matchingId]
			removeMatching: (ref) => {
				let matchingId = ref;
				// let matchingId = ref instanceof Model.QualificationAllocator ? ref.id : ref;
				let retval = matchingId;

				set((state) => {
					return {
						...state,
						program_qualifications: state.program_qualifications.filter((q) => q.id !== matchingId)
					}
				});

				return retval;
			},

			// set matching credits
			setMatchingCredits: (matchingId, credits) => {

				let retval = 0;

				set((state) => {
					const match = state.program_qualifications.find(q => q.id === matchingId);
					
					const courseCredits = state.courses.find(c => c.id === match.course).credits;
					const courseAllocatedCredits = state.getTotalAllocatedCreditsByCourseId(match.application_program, match.course);
					
					const currentCredits = match.credits;

					// * parse number
					// * clamp negative values to 0
					// * clamp to remaining credits from course
					let validCredits = Math.min(Math.max(0, parseFloat(credits) || 0), courseCredits - courseAllocatedCredits + currentCredits);
					retval = validCredits;

					return {
						...state,
						program_qualifications: state.program_qualifications.map((q) =>
							q.id === matchingId ? { ...q, credits: validCredits } : q
						)
					}
				});

				return retval;
			},

			incrementMatchingCredits: (matchingId, step = 0.5) => {
				const newCredits = get().program_qualifications.find(q => q.id === matchingId).credits + step;
				return get().setMatchingCredits(matchingId, newCredits);
			},

			decrementMatchingCredits: (matchingId, step = 0.5) => {
				const newCredits = get().program_qualifications.find(q => q.id === matchingId).credits - step;
				return get().setMatchingCredits(matchingId, newCredits);
			},

			getTotalAllocatedCreditsByCourseId: (applicationProgramId, courseId) => {
				return get().program_qualifications
					.filter(q => q.course === courseId && q.application_program === applicationProgramId)
					.map(q => q.credits)
					.reduce((acc, val) => { return acc + val; }, 0);
			},

			getAllocatedQualificationsByPrereqCourse: (applicationProgramId, prerequisiteCourseId) => {
				return get().program_qualifications.filter(q => q.target === prerequisiteCourseId && q.application_program === applicationProgramId);
			},


			exportJSON: () => {
				return JSON.stringify(get());
			},

			resetStore: () => {
				set(() => {
					return new Model.StudentApplication();
				});
			},
		})

	),
		{
			version: Model.version,
			name: 'tuhh-ects-2.0',
			storage: createJSONStorage(() => sessionStorage),
			migrate: (persistedState, version) => {
				
				// handle versions
				if (version !== Model.version) {
					console.log("persisted store version mismatch");
					return {};
				}
				
				return persistedState
			},
			merge: (persistedState, currentState) => {

				// store is to old
				if (Date.now() - persistedState.date > MAX_STORE_AGE) {
					console.log("persisted store is to old");
					return currentState;
				}
				
				// update date
				persistedState.date = Date.now();

				return {
					...currentState,
					...persistedState
				}
			}
		})
);

export default Store;
