import { useState } from 'react';
import { Accordion, Button, Row, Col } from 'react-bootstrap';
import { BsFillPenFill, BsFillTrash3Fill } from 'react-icons/bs';

import Store from '@model/Store';
import Course from '@components/Course';
import DegreeDetailsModal from '@components/DegreeDetailsModal';
import CourseDetailsModal from '@components/CourseDetailsModal';
import * as Model from '@model/Types';


// component representing a degree as an accordion item
const Degree = (props) => {
    
    // all courses of this degree
    const courses = Store(state => state.courses.filter((c) => c.degree === props.degree.id));

    // degree information
    const granting_institution = props.degree.granting_institution.name;
    const college = props.degree.granting_institution.college;

    // modal for editing degree information: institute, college, ...
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const handleOpenDetailsModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowDetailsModal(true);
    };

    const handleCloseDetailsModal = () => setShowDetailsModal(false);

    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        // delete degree from store
        Store.getState().removeDegree(props.degree.id);
    };


    // modal for adding new course
    const [showNewCourseModal, setShowNewCourseModal] = useState(false);
    const [newCourse, setNewCourse] = useState(new Model.Course(props.degree.id));

    const handleOpenNewCourseModal = () => setShowNewCourseModal(true);
    const handleCloseNewCourseModal = () => setShowNewCourseModal(false);

    const handleNewCourse = () => {
        setNewCourse(new Model.Course(props.degree.id));
        handleOpenNewCourseModal();
    }

    // get the header for the accordion item
    const getAccordionHeader = (institution, college) => {
        if (institution !== "" && college !== "")
        return institution + ", " + college;
        else if (institution !== "")
        return institution;
        else if (college !== "")
        return college;
        else
        return "No institution or college specified"; // unreachable
    }        

    return (
        <>
            <Accordion.Item eventKey={"degree-" + props.item} as="div">
                <Accordion.Header>
                    <Col className="degree-title">
                        { 
                            getAccordionHeader(granting_institution, college)
                        }
                    </Col>
                    <Col sm="auto">
                        <Button as="div" variant="outline-secondary" size="sm" className="ms-2" onClick={handleOpenDetailsModal}><BsFillPenFill /></Button>
                        <Button as="div" variant="outline-secondary" size="sm" className="ms-2 delete-degree" onClick={handleDelete}><BsFillTrash3Fill /></Button>
                    </Col>
                </Accordion.Header>
                <Accordion.Body className="degree-body">
                    <Row>
                        {/* the modal for editing degree information: included here since component returns an Accordion.Item */}
                        <DegreeDetailsModal degree={props.degree} show={showDetailsModal} handleClose={handleCloseDetailsModal} createNew={false} />
                    </Row>
                    <Row className="degree-courses" xs={1} md={2} lg={3}>
                        {                            
                            // show all courses for this degree
                            courses.map((course, index) => {
                                return (
                                    <Course course={course} degree={props.degree} key={index} />
                                );
                            })
                        }
                        <Col className="course-col">
                            <Row className="add-course-row">
                                <Col>
                                    <Button variant="outline-primary" className="add-course" onClick={handleNewCourse}>Add Module/Lecture</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <CourseDetailsModal course={newCourse} show={showNewCourseModal} handleClose={handleCloseNewCourseModal} createNew={true} />
        </>
    );
    
}

export default Degree;