import React from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '@shared/reportWebVitals';
import frontEndHelpers from '@shared/frontEndHelpers';

import 'bootstrap/dist/css/bootstrap.min.css'

import App from '@app/App';
import { ApplicationPrograms } from "@application_programs/ApplicationPrograms";

const root = ReactDOM.createRoot(document.getElementById('root'));

const default_application_program = ApplicationPrograms.standard().id;


root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        {/* default program redirection */}
        <Route path="/" element={<Navigate to={`/${default_application_program}`} />} />

        {/* app routes */}
        <Route path="/:application_program_id" element={<App />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
frontEndHelpers();
