import { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { BsFillTrash3Fill } from 'react-icons/bs';

import Store from '@model/Store';
import { externalToInternalCredits } from '@model/CreditPointCalc';


const PrerequisiteCourse = (props) => {

    // application_program
    // field
    // prerequisite_course

    // true if the total credits of the prerequisite course over all matchings exceed the required credits
    const [ exceededRequirements, setExceededRequirements ] = useState(false);
    // remaining credits to reach the required credits
    const [ remainingCredits, setRemainingCredits ] = useState(0);
    
    // progress bar values
    const [ totalInternalCreditsProgressBar, setTotalInternalCreditsProgressBar ] = useState(0);
    const [ remainingCreditsProgressBar, setRemainingCreditsProgressBar ] = useState(0);

    // calculate total credits for the prerequisite course over all matchings
    const totalInternalCredits = Store(state => state.program_qualifications.reduce(
        (accumulator, qualification) => {
            if (qualification.target !== props.prerequisite_course.id || qualification.application_program !== props.application_program.id) { 
                return accumulator;
            }
            
            // get degree for getting the total external credits
            const degree = state.degrees.find((d) => d.id === qualification.degree);
            // convert external credits to internal credits
            const internalCredits = externalToInternalCredits(degree.program.credits_nominal, degree.program.duration_nominal, qualification.credits);

            accumulator += internalCredits;

            return accumulator;
        }, 0)
    );
    
    const requiredCredits = props.prerequisite_course.required_cp;

    useEffect(() => {

        // check if the total credits of the prerequisite course over all matchings exceed the required credits
        let tmpExceededRequirements = totalInternalCredits > requiredCredits;

        // calculate remaining credits to reach the required credits: remaining credits are bound to [0; required credits]
        let tmpRemainingCredits = requiredCredits - totalInternalCredits;
        tmpRemainingCredits = tmpRemainingCredits < 0 ? 0 : tmpRemainingCredits;
        setRemainingCredits(tmpRemainingCredits);
        
        // bound the total internal credits to [0; required credits]
        let tmpTotalCredits = (totalInternalCredits > requiredCredits) ? requiredCredits : totalInternalCredits;
        
        // get percentages for progress bar
        tmpRemainingCredits = (tmpRemainingCredits / requiredCredits).toFixed(2) * 100;
        tmpTotalCredits = (tmpTotalCredits / requiredCredits).toFixed(2) * 100;

        // subtract 10 for (+) sign if the requirements are exceeded
        if (tmpExceededRequirements) {
            tmpRemainingCredits = 0;
            tmpTotalCredits = 90;
        }

        // set values to state
        setRemainingCreditsProgressBar(tmpRemainingCredits);
        setTotalInternalCreditsProgressBar(tmpTotalCredits);
        setExceededRequirements(totalInternalCredits > requiredCredits);

    }, [totalInternalCredits, requiredCredits]);


    return (
        <>
            <Card className="prerequisite-course">
                <Card.Header>
                    <Row>
                        <Col className="course-title">
                            {props.prerequisite_course.subject}
                        </Col>
                        <Col className="col-auto">
                            <Button as="div" variant="outline-secondary" size="sm" className="ms-2"><BsFillTrash3Fill /></Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Card.Text>{props.prerequisite_course.description}</Card.Text>
                    <Card.Text>Credit Points (ECTS): {requiredCredits}</Card.Text>
                    <ProgressBar>
                        <ProgressBar variant="success" now={ totalInternalCreditsProgressBar } key={1} label={totalInternalCredits.toFixed(2)} />
                        <ProgressBar variant="secondary" now={ remainingCreditsProgressBar } key={2} label={remainingCredits.toFixed(2)} />
                        <ProgressBar variant="primary" now={exceededRequirements ? 10 : 0} key={3} label={"+"} />
                    </ProgressBar>
                </Card.Body>
            </Card>

        </>
    );

}

export default PrerequisiteCourse;